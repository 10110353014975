import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import cockroachdbImg from "../../../components/databasewaitlist/images/cockroachdb.png";

const ogImage = {
  ogImage: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667416296/main-web/CockroachDB-REST_tiozua.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/cockroachdb/";

const CockroachDB = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant Realtime GraphQL and REST APIs on CockroachDB - Hasura"
      description="Connect Hasura to existing/new CockroachDB DBs for instant, realtime GraphQL and REST APIs."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST APIs on CockroachDB"
      desc="Point to your CockroachDB instance and get secure and performant REST APIs in under 2 minutes!"
      btnLink="https://cloud.hasura.io/signup?pg=cockroachdb&plcmt=hero&cta=try-it-out-in-30s&tech=default"
      btnText="Try it out in 30 seconds"
      imgSrc={cockroachdbImg}
      linkUrl="https://hasura.io/docs/latest/databases/postgres/cockroachdb/index/"
      linkText="Learn more in the docs"
      secondaryLinkUrl="https://hasura.io/blog/announcing-native-cockroachdb-integration/"
      secondaryLinkText="Read launch blog"
    />
  </Layout>
);

export default CockroachDB;
